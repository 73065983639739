import Vue from 'vue'

export default function (row) {
  let mensagem = ''
  if (row.situacao === 'CPF sem dados') {
    mensagem = `Atenção! CPF ${row.cadastroFederal} inexistente ou não constam matrículas CEI vinculados ao CPF. Para maiores\
 orientações, dirija-se à unidade de atendimento da Receita Federal. Verifique como obter o atendimento deste serviço na unidade\
 de sua jurisdição no sítio da Receita Federal do Brasil, na opção Unidades de Atendimento.`
  } else if (row.situacao === 'Sem acesso para filial') {
    mensagem = `CNPJ nº ${row.cadastroFederal} ${row.razaoSocial} está vinculado com sua matriz. Conforme legislação vigente\
 , o parcelamento somente pode ser concedido ao estabelecimento do centralizador. Para maiores orientações, dirija-se à unidade\
 de atendimento da Receita Federal. Verifique como obter o atendimento deste serviço na unidade de sua jurisdição no sítio da\
 Receita Federal do Brasil, na opção Unidades de Atendimento.`
  }

  if (mensagem) {
    Vue.swal({
      title: '<div class="text-danger">Atenção</div>',
      width: '40%',
      padding: '3em',
      html:
          `<div class="text-primary">
              ${mensagem}        
          </div>
          <br>
          <div class="text-danger">
              Atenciosamente, Equipe Monitor Contábil
          </div>`,
      icon: 'info',
      iconColor: '#ea5455',
      showConfirmButton: false,
      allowEscapeKey: true,
      customClass: {
        popup: 'swal-m',
      },
    })
  }
}
